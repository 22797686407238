
















































import { Component, Vue, Watch } from 'vue-property-decorator'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import { LogsListItemType } from '@/types/logs'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import LogsTable from '@/components/logs/LogsTable.vue'
import { UsersListItemType } from '@/types/transactions'
import Drawer from '@/components/common/Drawer.vue'
import UserDetail from '@/components/transactions/UserDetail.vue'
import FilterTags from '@/components/common/FilterTags.vue'

@Component({
  components: { FilterTags, UserDetail, Drawer, LogsTable, EllipseCommon },
})
export default class Logs extends Vue {
  loading = false
  loadingInfo = false

  page = 1

  order: TableOrderType | Record<string, any> = {}
  filters: TableFilterType[] = []
  filterNames = {
    UF_URL: 'URL',
    UF_RESPONSE_CODE: 'Код ответа',
    '>=UF_DATE': 'Дата обращения',
    USER_LOGIN: 'Пользователь',
  }

  showUserInfo = false

  get logs(): LogsListItemType[] {
    return this.$store.getters['logs/logsList']
  }

  get logsTotal(): string {
    return this.$store.getters['logs/logsTotal']
  }

  get logsExport(): TableExportType {
    return this.$store.getters['logs/exportLogs']
  }

  get userDetail(): UsersListItemType {
    return this.$store.getters['users/usersDetail']
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data: { target: string; data: string }) => {
      params[data.target] = data.data
    })

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get showedCount(): string | number {
    if (this.logsTotal) {
      return 10 * this.page > parseInt(this.logsTotal)
        ? this.logsTotal
        : 10 * this.page
    } else {
      return 0
    }
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('.logs__content') as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = this.tableFilters
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.loading = true

    await this.$store.dispatch('logs/getLogsList', params)

    this.loading = false
  }

  async handleExportLogs(): Promise<void> {
    await this.$store.dispatch('logs/getExportLogs')

    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.logsExport.link
    )
    link.setAttribute('download', this.logsExport.name)

    console.log(process.env.VUE_APP_BASE_URI + this.logsExport.link)
    // link.click()
    // link.remove()
  }

  handleAddTableFilter(data: TableFilterType): void {
    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: this.filterNames[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: this.filterNames[data.target],
          })
        }
      })
    }
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data
    this.handleChangePage()
  }

  async handleShowUserInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showUserInfo = true

    await this.$store.dispatch('users/getUsersDetail', id)

    this.loadingInfo = false
  }

  @Watch('filters')
  onFiltersChange(): void {
    this.handleChangePage()
  }

  @Watch('page')
  onPageChange(): void {
    this.handleChangePage()
  }

  async mounted(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('logs/getLogsList')

    this.loading = false
  }
}
