






























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  LogsListItemType,
  ParsedRequestType,
  ParsedResponseType,
} from '@/types/logs'
import InputCommon from '@/components/common/InputCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
@Component({
  components: { DatePickerCommon, InputCommon },
})
export default class LogsTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  logs!: LogsListItemType[]

  urlFilter = ''
  codeResponseFilter = ''
  dateFilter = ''
  userFilter = ''

  parseTableItem(
    item: string
  ): ParsedResponseType | ParsedRequestType[] | string {
    if (item !== '') {
      return JSON.parse(item)
    }

    return ''
  }

  handleShowLogInfo(row: { ID: number }): void {
    this.$emit('show-log-info', row.ID)
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(data: string, target: string): void {
    this.$emit('add-filter', { data, target })
  }
  handleShowUserInfo(id: string): void {
    this.$emit('show-user-info', id)
  }
}
