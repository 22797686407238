







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { UsersListItemType } from '@/types/users'

@Component
export default class UserDetail extends Vue {
  @Prop({ type: Object })
  user!: UsersListItemType

  setImage(url: string): string {
    return process.env.VUE_APP_BASE_URI + url
  }
}
